/* eslint-disable react-hooks/exhaustive-deps */
import { TextField } from "@mui/material";
import AutocompleteForFormikMulti from "_components/AutocompleteForFormikMulti";
import SelectModal from "_components/SelectList/SelectModal";
import { getAllMediaList, getAllStoreList, getMediaMetaData, getNavigationMetadataByStoreId, getNavigationTableDataByStoreId } from "_store/merchandising.slice";
import {
  getAllLocations,
  getAllOrderLineItemRowData,
  getOrderLineItemMetaData,
  getOrdersMetaData,
  getOrdersRowData,
} from "_store/orders.slice";
import {
  getAddOnMetaDataByAddOnId,
  getAddOnRowData,
  getAddOnTagByAddOnId,
  getAddOnTypesRowData,
} from "_store/pim/addOn.slice";
import {
  getCollectionsPropertiesMetaData,
  getCollectionsPropertiesRowData,
  getCollectionsTagByCollectionsId,
  getCollectionsTypesList,
  getCollectionsTypesMetaData,
  getCollectionsTypesRowData,
  getCollectionssMetaData,
  getCollectionssRowData,
} from "_store/pim/collections.slice";
import {
  getComponentsMetaDataByComponenetTypeId,
  getComponentsRowData,
  getComponentsTagByComponentsId,
  getComponentsTypesRowData,
} from "_store/pim/components.slice";
import { getCouponsMetaData, getCouponsRowData, getCouponsTypeList } from "_store/pim/coupons.slice";
import {
  getCustomerMetaData,
  getCustomerRowData,
} from "_store/pim/customers.slice";
import {
  getFindingTypesRowData,
  getFindingsMetaData,
  getFindingsRowData,
  getFindingsTagByFindingId,
} from "_store/pim/finding.slice";
import {
  getMetalPropertiesMetaData,
  getMetalPropertiesRowData,
  getMetalTagByMetalId,
  getMetalTypesMetaData,
  getMetalTypesRowData,
  getMetalsMetaDataByMetalId,
  getMetalsRowData,
} from "_store/pim/metal.slice";
import { getMouldMetaData, getMouldRowData } from "_store/pim/mould.slice";
import { getPositionsMetaData, getPositionsRowData } from "_store/pim/position.slice";
import { getPromotionsMetaData, getPromotionsRowData, getPromotionsTypeList } from "_store/pim/promotions.slice";
import {
  getServiceTypesRowData,
  getServicesMetaDataByServiceId,
  getServicesRowData,
  getServicesTagByServicesId,
} from "_store/pim/service.slice";
import { getVendorRowData } from "_store/pim/shipmentTracking.slice";
import { getStonePropertiesMetaData, getStonePropertiesRowData, getStonesMetaDataByStoneId, getStonesRowData, getStoneTagByStoneId, getStoneTypeMetaData, getStoneTypeRowData } from "_store/pim/stone.slice";
import {
  getInventoryTagMetaData,
  getInventoryTagRowData,
  getJewelleryProductMetaData,
  getJewelleryProductRowData,
  getStockTagMetaData,
  getStockTagRowData,
} from "_store/pim/storageManagement.slice";
import {
  getVariantOptionMetaData,
  getVariantOptionRowData,
} from "_store/pim/variants.slice";
import { getVendorMetaData } from "_store/pim/vendor.slice";
import {
  getOrderDemandLineItemAddonMetaData,
  getOrderDemandLineItemAddonRowData,
  getOrderDemandLineItemFindingsMetaData,
  getOrderDemandLineItemFindingsRowData,
  getOrderDemandLineItemFullProductMetaData,
  getOrderDemandLineItemFullProductRowData,
  getOrderDemandLineItemMetalMetaData,
  getOrderDemandLineItemMetalRowData,
  getOrderDemandLineItemStoneMetaData,
  getOrderDemandLineItemStoneRowData,
  getPoLineItemMetaData,
  getPoLineItemRowData,
  getPoMetaData,
  getPoRowData,
  getPrLineItemMetaData,
  getPrLineItemRowData,
} from "_store/procurement.slice";
import { Field } from "formik";
import { useEffect, useRef, useState } from "react";

export default function RenderFilterModal({
  fieldType = "multiSelect", // singleSelect, multiSelect
  value,
  name,
  id,
  label,
  disabled = false,
  readOnly = false,
  setFieldValue,
  types = [],
  title,
  filterType, // collection, collectionType, service, addOn, orderLineItem, variantOption, jewelleryProduct, metal, finding, mould, promotion, stone, vendor, stoneType, position, metalType
  defaultItems = [], // default selected items
  onChange = null,
  typeDropdownName,
  ...rest
}) {
  const [opetionListItems, setOpetionListItems] = useState([]);
  const firstRender = useRef(true);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (!firstRender.current && !defaultItems?.length) {
      setOpetionListItems([]);
      setFieldValue(name, []);
    } else {
      if (defaultItems?.length > 0) {
        firstRender.current = false;
        setOpetionListItems(defaultItems);
      }
    }
  }, [filterType, defaultItems.length]);

  useEffect(() => {
    if (defaultItems?.length > 0) {
      setOpetionListItems(defaultItems);
    }
  }, [defaultItems])

  useEffect(() => {
    if (!firstRender.current && onChange) {
      onChange({ items: opetionListItems });
    } else {
      firstRender.current = false;
    }
  }, [opetionListItems.length]);
  return (
    <div className="select-wrapper">
      {fieldType === "multiSelect" ? (
        <Field
          className="w-100"
          component={AutocompleteForFormikMulti}
          id={id}
          name={name}
          label={label}
          as="select"
          value={value ?? []}
          placeholder="Select Component"
          options={opetionListItems}
          disabled={disabled}
          open={false}
          readOnly={readOnly}
        />
      ) : (
        <TextField
          id={id}
          name={name}
          label={label}
          value={opetionListItems?.[0]?.label ?? ""}
          margin="normal"
          fullWidth
          readOnly={true}
          disabled={disabled}
        />
      )}
      {!disabled && (
        <div className="icon" onClick={() => setOpen(true)}>
          <span className="material-icons">search</span>
        </div>
      )}
      {filterType === "collection" && (
        <SelectModal
          open={open}
          handleClose={() => setOpen(false)}
          onConfirm={(optionList) => {
            setOpen(false);
            setOpetionListItems(optionList);
            if(rest?.onDataConfirm) rest?.onDataConfirm(optionList)
          }}
          setFieldValue={setFieldValue}
          fieldName={name}
          defaultSelected={value}
          name="Collection"
          getMetaData={getCollectionssMetaData}
          getRowData={getCollectionssRowData}
          selectType={fieldType}
          isTypeSelect={true}
          getsTypesRowData={getCollectionsTypesList}
          getTagById={getCollectionsTagByCollectionsId}
          dataList={opetionListItems}
          types={types.length > 0 ? types : null}
          paramTypeIdName="collectionTypeId"
          {...rest}
        />
      )}
      {filterType === "collectionType" && (
        <SelectModal
          open={open}
          handleClose={() => setOpen(false)}
          onConfirm={(optionList) => {
            setOpen(false);
            setOpetionListItems(optionList);
          }}
          setFieldValue={setFieldValue}
          fieldName={name}
          defaultSelected={value}
          name="Collection Type"
          getMetaData={getCollectionsTypesMetaData}
          getRowData={getCollectionsTypesRowData}
          selectType="multiSelect"
          dataList={opetionListItems}
          {...rest}
        />
      )}

      {filterType === "jewelleryProduct" && (
        <SelectModal
          open={open}
          handleClose={() => setOpen(false)}
          onConfirm={(optionList) => {
            setOpen(false);
            setOpetionListItems(optionList);
          }}
          setFieldValue={setFieldValue}
          fieldName={name}
          defaultSelected={value}
          name="Jewellery Product"
          getMetaData={getJewelleryProductMetaData}
          getRowData={getJewelleryProductRowData}
          selectType={fieldType || "multiSelect"}
          dataList={opetionListItems}
        />
      )}

      {filterType === "metal" ? (
        <SelectModal
          open={open}
          handleClose={() => setOpen(false)}
          onConfirm={(optionList) => {
            setOpen(false);
            setOpetionListItems(optionList);
          }}
          setFieldValue={setFieldValue}
          fieldName={name}
          defaultSelected={value}
          name="Metal"
          getMetaData={getMetalsMetaDataByMetalId}
          getRowData={getMetalsRowData}
          selectType="multiSelect"
          dataList={opetionListItems}
          getsTypesRowData={getMetalTypesRowData}
          isTypeSelect={true}
          getTagById={getMetalTagByMetalId}
          paramTypeIdName="metalTypeId"
          {...rest}
        />
      ) : null}

      {filterType === "stone" ? (
        <SelectModal
          open={open}
          handleClose={() => setOpen(false)}
          onConfirm={(optionList) => {
            setOpen(false);
            setOpetionListItems(optionList);
          }}
          setFieldValue={setFieldValue}
          fieldName={name}
          defaultSelected={value}
          name="Stone"
          getMetaData={getStonesMetaDataByStoneId}
          getRowData={getStonesRowData}
          selectType="multiSelect"
          dataList={opetionListItems}
          getsTypesRowData={getStoneTypeRowData}
          isTypeSelect={true}
          getTagById={getStoneTagByStoneId}
          paramTypeIdName="stoneTypeId"
          {...rest}
        />
      ) : null}

      {filterType === "vendor" ? (
        <SelectModal
          open={open}
          handleClose={() => setOpen(false)}
          onConfirm={(optionList) => {
            setOpen(false);
            setOpetionListItems(optionList);
          }}
          setFieldValue={setFieldValue}
          fieldName={name}
          defaultSelected={value}
          name="Vendor"
          getMetaData={getVendorMetaData}
          getRowData={getVendorRowData}
          selectType="multiSelect"
          dataList={opetionListItems}
          paramTypeIdName="vendorId"
          {...rest}
        />
      ) : null}

      {filterType === "stoneType" ? (
        <SelectModal
          open={open}
          handleClose={() => setOpen(false)}
          onConfirm={(optionList) => {
            setOpen(false);
            setOpetionListItems(optionList);
          }}
          setFieldValue={setFieldValue}
          fieldName={name}
          defaultSelected={value}
          name="Stone Type"
          getMetaData={getStoneTypeMetaData}
          getRowData={getStoneTypeRowData}
          selectType="multiSelect"
          dataList={opetionListItems}
          paramTypeIdName="stoneType"
          {...rest}
        />
      ) : null}

      {filterType === "metalType" ? (
        <SelectModal
          open={open}
          handleClose={() => setOpen(false)}
          onConfirm={(optionList) => {
            setOpen(false);
            setOpetionListItems(optionList);
          }}
          setFieldValue={setFieldValue}
          fieldName={name}
          defaultSelected={value}
          name="Metal Type"
          getMetaData={getMetalTypesMetaData}
          getRowData={getMetalTypesRowData}
          selectType="multiSelect"
          dataList={opetionListItems}
          paramTypeIdName="stoneType"
          {...rest}
        />
      ) : null}

      {filterType === "position" ? (
        <SelectModal
          open={open}
          handleClose={() => setOpen(false)}
          onConfirm={(optionList) => {
            setOpen(false);
            setOpetionListItems(optionList);
          }}
          setFieldValue={setFieldValue}
          fieldName={name}
          defaultSelected={value}
          name="Position"
          getMetaData={getPositionsMetaData}
          getRowData={getPositionsRowData}
          selectType="multiSelect"
          dataList={opetionListItems}
          paramTypeIdName="position"
          {...rest}
        />
      ) : null}

      {filterType === "service" && (
        <SelectModal
          open={open}
          handleClose={() => setOpen(false)}
          onConfirm={(optionList) => {
            setOpen(false);
            setOpetionListItems(optionList);
          }}
          setFieldValue={setFieldValue}
          fieldName={name}
          defaultSelected={value}
          name="Service"
          getMetaData={getServicesMetaDataByServiceId}
          getRowData={getServicesRowData}
          selectType="multiSelect"
          isTypeSelect={true}
          getsTypesRowData={getServiceTypesRowData}
          getTagById={getServicesTagByServicesId}
          dataList={opetionListItems}
          types={types.length > 0 ? types : null}
          paramTypeIdName="serviceTypeId"
        />
      )}

      {filterType === "addOn" && (
        <SelectModal
          open={open}
          handleClose={() => setOpen(false)}
          onConfirm={(optionList) => {
            setOpen(false);
            setOpetionListItems(optionList);
          }}
          setFieldValue={setFieldValue}
          fieldName={name}
          defaultSelected={value}
          name="Add-On"
          getMetaData={getAddOnMetaDataByAddOnId}
          getRowData={getAddOnRowData}
          selectType="multiSelect"
          isTypeSelect={true}
          getsTypesRowData={getAddOnTypesRowData}
          getTagById={getAddOnTagByAddOnId}
          dataList={opetionListItems}
          types={types.length > 0 ? types : null}
          paramTypeIdName="addonTypeId"
        />
      )}
      {filterType === "orderLineItem" && (
        <SelectModal
          open={open}
          handleClose={() => setOpen(false)}
          onConfirm={(optionList) => {
            setOpen(false);
            setOpetionListItems(optionList);
          }}
          setFieldValue={setFieldValue}
          fieldName={name}
          defaultSelected={value}
          name="Order Line Items"
          getMetaData={getOrderLineItemMetaData}
          getRowData={getAllOrderLineItemRowData}
          selectType={fieldType}
          dataList={opetionListItems}
        />
      )}

      {filterType === "component" && (
        <SelectModal
          open={open}
          handleClose={() => setOpen(false)}
          onConfirm={(optionList) => {
            setOpen(false);
            setOpetionListItems(optionList);
          }}
          setFieldValue={setFieldValue}
          fieldName={name}
          defaultSelected={value}
          name="Component"
          getMetaData={getComponentsMetaDataByComponenetTypeId}
          getRowData={getComponentsRowData}
          selectType="multiSelect"
          isTypeSelect={true}
          getsTypesRowData={getComponentsTypesRowData}
          getTagById={getComponentsTagByComponentsId}
          dataList={opetionListItems}
          types={types.length > 0 ? types : null}
          paramTypeIdName="componentTypeId"
        />
      )}

      {filterType === "variantOption" && (
        <SelectModal
          open={open}
          handleClose={() => setOpen(false)}
          onConfirm={(optionList) => {
            setOpen(false);
            setOpetionListItems(optionList);
          }}
          setFieldValue={setFieldValue}
          fieldName={name}
          defaultSelected={value}
          name="Variant"
          getMetaData={getVariantOptionMetaData}
          getRowData={getVariantOptionRowData}
          selectType="multiSelect"
          dataList={opetionListItems}
          {...rest}
        />
      )}

      {filterType === "position" && fieldType === "singleSelect"? (
        <SelectModal
          open={open}
          handleClose={() => setOpen(false)}
          onConfirm={(optionList) => {
            setOpen(false);
            setOpetionListItems(optionList);
          }}
          setFieldValue={setFieldValue}
          fieldName={name}
          defaultSelected={value}
          name="Position"
          getMetaData={getPositionsMetaData}
          getRowData={getPositionsRowData}
          selectType="singleSelect"
          dataList={opetionListItems}
          paramTypeIdName="position"
          {...rest}
        />
      ) : null}

      {filterType === "orderDemandLineItemStone" &&
        fieldType === "singleSelect" ? (
        <SelectModal
          open={open}
          handleClose={() => setOpen(false)}
          onConfirm={(optionList) => {
            setOpen(false);
            setOpetionListItems(optionList);
          }}
          setFieldValue={setFieldValue}
          fieldName={name}
          defaultSelected={value}
          name="Variant"
          getMetaData={getOrderDemandLineItemStoneMetaData}
          getRowData={getOrderDemandLineItemStoneRowData}
          selectType="singleSelect"
          dataList={opetionListItems}
          {...rest}
        />
      ) : null}

      {filterType === "orderDemandLineItemMetal" &&
        fieldType === "singleSelect" ? (
        <SelectModal
          open={open}
          handleClose={() => setOpen(false)}
          onConfirm={(optionList) => {
            setOpen(false);
            setOpetionListItems(optionList);
          }}
          setFieldValue={setFieldValue}
          fieldName={name}
          defaultSelected={value}
          name="Demand Line Item Metal"
          getMetaData={getOrderDemandLineItemMetalMetaData}
          getRowData={getOrderDemandLineItemMetalRowData}
          selectType="singleSelect"
          dataList={opetionListItems}
          {...rest}
        />
      ) : null}

      {filterType === "orderDemandLineItemFinding" &&
        fieldType === "singleSelect" ? (
        <SelectModal
          open={open}
          handleClose={() => setOpen(false)}
          onConfirm={(optionList) => {
            setOpen(false);
            setOpetionListItems(optionList);
          }}
          setFieldValue={setFieldValue}
          fieldName={name}
          defaultSelected={value}
          name="Demand Line Item Finding"
          getMetaData={getOrderDemandLineItemFindingsMetaData}
          getRowData={getOrderDemandLineItemFindingsRowData}
          selectType="singleSelect"
          dataList={opetionListItems}
          {...rest}
        />
      ) : null}

      {filterType === "orderLineItemDemandAddon" &&
        fieldType === "singleSelect" ? (
        <SelectModal
          open={open}
          handleClose={() => setOpen(false)}
          onConfirm={(optionList) => {
            setOpen(false);
            setOpetionListItems(optionList);
          }}
          setFieldValue={setFieldValue}
          fieldName={name}
          defaultSelected={value}
          name="Demand Line Item AddOn"
          getMetaData={getOrderDemandLineItemAddonMetaData}
          getRowData={getOrderDemandLineItemAddonRowData}
          selectType="singleSelect"
          dataList={opetionListItems}
          {...rest}
        />
      ) : null}

      {filterType === "orderLineItemDemandFullProduct" &&
        fieldType === "singleSelect" ? (
        <SelectModal
          open={open}
          handleClose={() => setOpen(false)}
          onConfirm={(optionList) => {
            setOpen(false);
            setOpetionListItems(optionList);
          }}
          setFieldValue={setFieldValue}
          fieldName={name}
          defaultSelected={value}
          name="Demand Line Item Full Product"
          getMetaData={getOrderDemandLineItemFullProductMetaData}
          getRowData={getOrderDemandLineItemFullProductRowData}
          selectType="singleSelect"
          dataList={opetionListItems}
          {...rest}
        />
      ) : null}

      {filterType === "poLineItem" && fieldType === "singleSelect" ? (
        <SelectModal
          open={open}
          handleClose={() => setOpen(false)}
          onConfirm={(optionList) => {
            setOpen(false);
            setOpetionListItems(optionList);
          }}
          setFieldValue={setFieldValue}
          fieldName={name}
          defaultSelected={value}
          name={label}
          getMetaData={getPoLineItemMetaData}
          getRowData={getPoLineItemRowData}
          selectType="singleSelect"
          dataList={opetionListItems}
          {...rest}
        />
      ) : null}

      {filterType === "poLineItem" && fieldType === "multiSelect" ? (
        <SelectModal
          open={open}
          handleClose={() => setOpen(false)}
          onConfirm={(optionList) => {
            setOpen(false);
            setOpetionListItems(optionList);
          }}
          setFieldValue={setFieldValue}
          fieldName={name}
          defaultSelected={value}
          name={label}
          getMetaData={getPoLineItemMetaData}
          getRowData={getPoLineItemRowData}
          selectType="multiSelect"
          dataList={opetionListItems}
          {...rest}
        />
      ) : null}

      {/* po */}
      {filterType === "po" && fieldType === "singleSelect" ? (
        <SelectModal
          open={open}
          handleClose={() => setOpen(false)}
          onConfirm={(optionList) => {
            setOpen(false);
            setOpetionListItems(optionList);
          }}
          setFieldValue={setFieldValue}
          fieldName={name}
          defaultSelected={value}
          name={label}
          getMetaData={getPoMetaData}
          getRowData={getPoRowData}
          selectType="singleSelect"
          dataList={opetionListItems}
          {...rest}
        />
      ) : null}

      {filterType === "prLineItem" && fieldType === "singleSelect" ? (
        <SelectModal
          open={open}
          handleClose={() => setOpen(false)}
          onConfirm={(optionList) => {
            setOpen(false);
            setOpetionListItems(optionList);
          }}
          setFieldValue={setFieldValue}
          fieldName={name}
          defaultSelected={value}
          name={label}
          getMetaData={getPrLineItemMetaData}
          getRowData={getPrLineItemRowData}
          selectType="singleSelect"
          dataList={opetionListItems}
          {...rest}
        />
      ) : null}

      {filterType === "stockTag" && fieldType === "singleSelect" ? (
        <SelectModal
          open={open}
          handleClose={() => setOpen(false)}
          onConfirm={(optionList) => {
            setOpen(false);
            setOpetionListItems(optionList);
          }}
          setFieldValue={setFieldValue}
          fieldName={name}
          defaultSelected={value}
          name={label}
          getMetaData={getStockTagMetaData}
          getRowData={getStockTagRowData}
          selectType="singleSelect"
          dataList={opetionListItems}
          {...rest}
        />
      ) : null}

      {filterType === "stockTag" && fieldType === "multiSelect" ? (
        <SelectModal
          open={open}
          handleClose={() => setOpen(false)}
          onConfirm={(optionList) => {
            setOpen(false);
            setOpetionListItems(optionList);
          }}
          setFieldValue={setFieldValue}
          fieldName={name}
          defaultSelected={value}
          name={label}
          getMetaData={getStockTagMetaData}
          getRowData={getStockTagRowData}
          selectType="multiSelect"
          dataList={opetionListItems}
          {...rest}
        />
      ) : null}
      {filterType === "image" && fieldType === "singleSelect" ? (
        <SelectModal
          open={open}
          handleClose={() => setOpen(false)}
          onConfirm={(optionList) => {
            setOpen(false);
            setOpetionListItems(optionList);
          }}
          setFieldValue={setFieldValue}
          fieldName={name}
          defaultSelected={value}
          name={label}
          getMetaData={getMediaMetaData}
          getRowData={getAllMediaList}
          selectType="singleSelect"
          dataList={opetionListItems}
          {...rest}
        />
      ) : null}

      {filterType === "inventoryTag" && fieldType === "multiSelect" ? (
        <SelectModal
          open={open}
          handleClose={() => setOpen(false)}
          onConfirm={(optionList) => {
            setOpen(false);
            setOpetionListItems(optionList);
          }}
          setFieldValue={setFieldValue}
          fieldName={name}
          defaultSelected={value}
          name={label}
          getMetaData={getInventoryTagMetaData}
          getRowData={getInventoryTagRowData}
          selectType="multiSelect"
          dataList={opetionListItems}
          {...rest}
        />
      ) : null}
      {filterType === "inventoryTag" && fieldType === "singleSelect" ? (
        <SelectModal
          open={open}
          handleClose={() => setOpen(false)}
          onConfirm={(optionList) => {
            setOpen(false);
            setOpetionListItems(optionList);
          }}
          setFieldValue={setFieldValue}
          fieldName={name}
          defaultSelected={value}
          name={label}
          getMetaData={getInventoryTagMetaData}
          getRowData={getInventoryTagRowData}
          selectType="singleSelect"
          getsTypesRowData={getAllLocations}
          isTypeSelect={true}
          paramTypeIdName="searchKey[relations][][inventoryTray.location.id]"
          // getTagById={getMetalTagByMetalId}
          dataList={opetionListItems}
          {...rest}
        />
      ) : null}

      {filterType === "order" && fieldType === "singleSelect" ? (
        <SelectModal
          open={open}
          handleClose={() => setOpen(false)}
          onConfirm={(optionList) => {
            setOpen(false);
            setOpetionListItems(optionList);
          }}
          setFieldValue={setFieldValue}
          fieldName={name}
          defaultSelected={value}
          name={label}
          getMetaData={getOrdersMetaData}
          getRowData={getOrdersRowData}
          selectType="singleSelect"
          dataList={opetionListItems}
          {...rest}
        />
      ) : null}
      {filterType === "customer" && fieldType === "singleSelect" ? (
        <SelectModal
          open={open}
          handleClose={() => setOpen(false)}
          onConfirm={(optionList) => {
            setOpen(false);
            setOpetionListItems(optionList);
          }}
          setFieldValue={setFieldValue}
          fieldName={name}
          defaultSelected={value}
          name={label}
          getMetaData={getCustomerMetaData}
          getRowData={getCustomerRowData}
          selectType="singleSelect"
          dataList={opetionListItems}
          {...rest}
        />
      ) : null}
      {filterType === "metal" && fieldType === "singleSelect" ? (
        <SelectModal
          open={open}
          handleClose={() => setOpen(false)}
          onConfirm={(optionList) => {
            setOpen(false);
            setOpetionListItems(optionList);
          }}
          setFieldValue={setFieldValue}
          fieldName={name}
          defaultSelected={value}
          name={label}
          getMetaData={getMetalsMetaDataByMetalId}
          getRowData={getMetalsRowData}
          selectType="singleSelect"
          dataList={opetionListItems}
          getsTypesRowData={getMetalTypesRowData}
          isTypeSelect={true}
          getTagById={getMetalTagByMetalId}
          paramTypeIdName="metalTypeId"
          {...rest}
        />
      ) : null}
      {filterType === "finding" && fieldType === "multiSelect" ? (
        <SelectModal
          open={open}
          handleClose={() => setOpen(false)}
          onConfirm={(optionList) => {
            setOpen(false);
            setOpetionListItems(optionList);
          }}
          setFieldValue={setFieldValue}
          fieldName={name}
          defaultSelected={value}
          name={label}
          getMetaData={getFindingsMetaData}
          getRowData={getFindingsRowData}
          selectType="multiSelect"
          dataList={opetionListItems}
          isTypeSelect={true}
          getsTypesRowData={getFindingTypesRowData}
          getTagById={getFindingsTagByFindingId}
          paramTypeIdName="findingTypeId"
          {...rest}
        />
      ) : null}

      {filterType === "mould" && fieldType === "multiSelect" ? (
        <SelectModal
          open={open}
          handleClose={() => setOpen(false)}
          onConfirm={(optionList) => {
            setOpen(false);
            setOpetionListItems(optionList);
          }}
          setFieldValue={setFieldValue}
          fieldName={name}
          defaultSelected={value}
          name={label}
          getMetaData={getMouldMetaData}
          getRowData={getMouldRowData}
          selectType={fieldType}
          dataList={opetionListItems}
          {...rest}
        />
      ) : null}
      {filterType === "promotion" && fieldType === "singleSelect" ? (
        <SelectModal
          open={open}
          handleClose={() => setOpen(false)}
          onConfirm={(optionList) => {
            setOpen(false);
            setOpetionListItems(optionList);
          }}
          setFieldValue={setFieldValue}
          fieldName={name}
          defaultSelected={value}
          name={label}
          getMetaData={getPromotionsMetaData}
          getRowData={getPromotionsRowData}
          selectType={fieldType}
          dataList={opetionListItems}
          isTypeSelect={true}
          getsTypesRowData={getPromotionsTypeList}
          paramTypeIdName="type"
          {...rest}
        />
      ) : null}
      {filterType === "coupon" && fieldType === "multiSelect" ? (
        <SelectModal
          open={open}
          handleClose={() => setOpen(false)}
          onConfirm={(optionList) => {
            setOpen(false);
            setOpetionListItems(optionList);
          }}
          setFieldValue={setFieldValue}
          fieldName={name}
          defaultSelected={value}
          name={label}
          getMetaData={getCouponsMetaData}
          getRowData={getCouponsRowData}
          selectType={fieldType}
          getsTypesRowData={getCouponsTypeList}
          dataList={opetionListItems}
          paramTypeIdName="type"
          isTypeSelect={true}
          // getTagById={getFindingsTagByFindingId} 
          {...rest}
        />
      ) : null}

      {filterType === "stoneProperty" && (
        <SelectModal
          open={open}
          handleClose={() => setOpen(false)}
          onConfirm={(optionList) => {
            setOpen(false);
            setOpetionListItems(optionList);
          }}
          setFieldValue={setFieldValue}
          fieldName={name}
          defaultSelected={value}
          name="Stone Property"
          getMetaData={getStonePropertiesMetaData}
          getRowData={getStonePropertiesRowData}
          selectType="multiSelect"
          dataList={opetionListItems}
          paramTypeIdName="stonePropertyTypeId"
        />
      )}

      {filterType === "metalProperty" && (
        <SelectModal
          open={open}
          handleClose={() => setOpen(false)}
          onConfirm={(optionList) => {
            setOpen(false);
            setOpetionListItems(optionList);
          }}
          setFieldValue={setFieldValue}
          fieldName={name}
          defaultSelected={value}
          name="Metal Property"
          getMetaData={getMetalPropertiesMetaData}
          getRowData={getMetalPropertiesRowData}
          selectType="multiSelect"
          dataList={opetionListItems}
          paramTypeIdName="metalPropertyValuesId"
        />
      )}

      {filterType === "navigationHierarchy" && (
        <SelectModal
          open={open}
          handleClose={() => setOpen(false)}
          onConfirm={(optionList) => {
            setOpen(false);
            setOpetionListItems(optionList);
          }}
          setFieldValue={setFieldValue}
          fieldName={name}
          defaultSelected={value}
          name="Navigation Hierarchy"
          getMetaData={getNavigationMetadataByStoreId}
          getRowData={getNavigationTableDataByStoreId}
          selectType="multiSelect"
          dataList={opetionListItems}
          isTypeSelect={true}
          getsTypesRowData={getAllStoreList}
          paramTypeIdName="storeId"
        />
      )}

      {filterType === "collectionProperty" && (
        <SelectModal
          open={open}
          handleClose={() => setOpen(false)}
          onConfirm={(optionList) => {
            setOpen(false);
            setOpetionListItems(optionList);
          }}
          setFieldValue={setFieldValue}
          fieldName={name}
          defaultSelected={value}
          name="Collection Property"
          getMetaData={getCollectionsPropertiesMetaData}
          getRowData={getCollectionsPropertiesRowData}
          selectType="multiSelect"
          dataList={opetionListItems}
          paramTypeIdName="collectionPropertyValuesId"
        />
      )}

      {filterType === "inventoryTagPricing" ? (
        <SelectModal
          open={open}
          handleClose={() => setOpen(false)}
          onConfirm={(optionList) => {
            setOpen(false);
            setOpetionListItems(optionList);
          }}
          setFieldValue={setFieldValue}
          fieldName={name}
          defaultSelected={value}
          name={label}
          typeDropdownName={typeDropdownName}
          getMetaData={getInventoryTagMetaData}
          getRowData={getInventoryTagRowData}
          selectType={fieldType}
          dataList={opetionListItems}
          isTypeSelect={true}
          getsTypesRowData={getAllLocations}
          paramTypeIdName="locationId"
          customParam={true}
          filterType={filterType}
          {...rest}
        />
      ) : null}


    </div>
  );
}
