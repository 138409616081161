/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from "react";
import { Divider, Button } from "@mui/material";
import { FieldArray } from "formik";
import { useDispatch } from "react-redux";
import { getAllStores } from "_store/pim/store.slice";
import ComponentSetImageRefField from "./componentSetImageRefField";

const ComponentSetImageRefModal = ({
	prefixName = "",
	onChange,
	onBlur,
	value,
	onReplaceGroupList,
	setFieldValue,
	disabled = false,
	action,
	name
}) => {
	const emptyComponentSetRef = {
		code: "",
		storeId: "",
	};
	const [isedited, setIsedited] = useState(false);
	const dispatch = useDispatch();
	const [storeData, setStoreData] = useState();
  const [componentSetRefVal, setComponentSetRefVal] = useState([]);
	const [loading, setLoading] = useState({
		storesList: true,
	});
		let ComponentSetRef = value?.length > 0 ? value : [emptyComponentSetRef];
		
		const setGroupValue = useCallback(
			(val) => {
				const data = ComponentSetRef;
				setComponentSetRefVal(data);
				if (typeof setFieldValue === "function") {
					setFieldValue(`${name}`, data);
				}
				if (typeof onReplaceGroupList === "function") {
					setComponentSetRefVal(data);
					onReplaceGroupList(val);
				}
			},
			[setFieldValue, value]
		);

		
		useEffect(() => {
			if (
				(action === "edit" || action === "view") && isedited &&
				ComponentSetRef?.length > 0
			) {
				setGroupValue(ComponentSetRef);
				setIsedited(!isedited);
			} else {
				setGroupValue([emptyComponentSetRef]);
			}
		}, [setGroupValue, action]);

	useEffect(() => {
		dispatch(getAllStores()).then((response) => {
			setStoreData(response?.payload?.data);
			setLoading((prevState) => ({
				...prevState,
				storesList: false,
			}));
		});
	}, [])

	const handleremoveArray = (subSeqindex) => {
		let array = JSON.parse(JSON.stringify(componentSetRefVal));
		array.splice(subSeqindex, 1);
		setFieldValue(`${name}`, array);
	};

	return (
		<>
			<div className="form-group drag_and_drop_block">
				<Divider />
				{componentSetRefVal?.length > 0 && (
					<>
						<FieldArray
							name={`${name}`}
							render={(formOption) =>
								componentSetRefVal?.map((blockValue, sIndex) => (
									<ComponentSetImageRefField
										prefixName={prefixName}
										key={`${sIndex}-collectionTypeId-${blockValue?.collectionId}`}
										index={sIndex}
										formOption={formOption}
										value={blockValue}
										onBlur={onBlur}
										onChange={onChange}
										isLast={sIndex === componentSetRefVal.length - 1}
										disabled={disabled}
										ComponentSetRef={componentSetRefVal}
										setFieldValue={setFieldValue}
										removeElement={handleremoveArray}
										loading={loading}
										storeData={storeData}
									/>
								))
							}
						/>

						<div className="w-100 mb-2 d-flex justify-content-end">
						{!disabled && (
                <Button
                  variant="outlined"
                  sx={{
                    mt: 1,
                    mb: 1,
                  }}
                  size="small"
                  disabled={disabled}
                  className="m-0 mt-1 ml-2"
                  onClick={() => {
                    componentSetRefVal?.push({
                      code: "",
                      storeId: "",
                    });
                    setFieldValue(`${name}`, componentSetRefVal);
                  }}
                >
                  <span className="mr-1 material-icons">add</span>
                  Add
                </Button>
              )}
						</div>
					</>
				)}
			</div>
		</>
	);
};

export default ComponentSetImageRefModal;
