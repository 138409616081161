import * as React from "react";
import InnerLoader from "./InnerLoader";
import { useSelector } from "react-redux";

const FormLoader = () => {
  const { loadingFormLoader } = useSelector((state) => state.commonData);
  if (!loadingFormLoader) return null;
  return <InnerLoader isBlockLoader={true} />;
};

export default FormLoader;
