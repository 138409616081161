import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { fetchWrapper } from "_utils";

const baseUrl = process.env.REACT_APP_API_URL;
const sliceName = "storeData";

export const getAllStores = createAsyncThunk(
  `${sliceName}/getAllStores`,
  async () => await fetchWrapper.get(`${baseUrl}/store/dropdown/getAll`)
);

export const addStoreBulkUpdates = createAsyncThunk(
  `${sliceName}/addStoreBulkUpdates`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/store/bulk-update`,
      values
    );
    return res || res === "" ? res : null;
  }
);

export const getAllStoreLocationsByStoreId = createAsyncThunk(
  `${sliceName}/getAllStoreLocationsByStoreId`,
  async (id) => await fetchWrapper.get(`${baseUrl}/store/${id}/location/getALL`)
);

export const stoneSlice = createSlice({
  name: sliceName,
  initialState: {},
});

export default stoneSlice.reducer;
