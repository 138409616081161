import { useNavigate, useParams, useSearchParams } from "react-router-dom";

export const useRedirectBack = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const listPage = searchParams.get("listPage")
    ? searchParams.get("listPage")
    : "";
  let { action: page } = useParams();

  const handleRedirect = (action = null) => {
    if (
      window.history.length <= 2 ||
      (["add", "update", "delete"].includes(action) &&
        listPage !== "versionHistory") ||
      (listPage !== "versionHistory" && page !== "view")
    ) {
      navigate("..");
    } else {
      navigate(-1);
    }
  };

  return handleRedirect;
}


export const useStateRedirectBack = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const typeId = searchParams.get("typeId") ? searchParams.get("typeId") :  searchParams.get("type") ??  "";
  const listPage = searchParams.get("listPage")
    ? searchParams.get("listPage")
    : "";
  let { action: page } = useParams();

  const handleRedirect = (action = null) => {
    if (
      window.history.length <= 2 ||
      (["add", "update", "delete"].includes(action) &&
        listPage !== "versionHistory") ||
      (listPage !== "versionHistory" && page !== "view")
    ) {
      navigate("..", {
        state: {
          type: typeId
        },
      });
    } else {
      navigate(-1, {
        state: {
          type: typeId
        },
      });
    }
  };

  return handleRedirect;
};
// export default useRedirectBack;
