/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Field } from "formik";
import BlockHeading from "_components/common/BlockHeading";
import CustomizedSelectForFormik from "_components/CustomizedSelectForFormik";
import { MenuItem, TextField } from "@mui/material";

const ComponentSetImageRefField = ({
	index,
	value,
	formOption,
	onBlur,
	onChange,
	dragHandleProps,
	isLast,
	disabled = false,
	setFieldValue,
	removeElement,
	ComponentSetRef,
	storeData,
	loading,
}) => {
	const namePrefix = `${formOption.name}[${index}]`;

	const handleRemove = () => {
		removeElement(index);
		// formOption.remove(index)
	};

	return (
		<>
			<BlockHeading
				index={index}
				isLast={isLast}
				dragHandleProps={dragHandleProps}
				disabled={disabled}
				onRemove={ComponentSetRef?.length > 1 ? handleRemove : null}
			>
				<div className="row row-sm">
					<div className="col-xl-6 col-md-6">
						<h4>Code</h4>
						<TextField
							id={`${namePrefix}.code`}
							label="code"
							name={`${namePrefix}.code`}
							value={value?.code}
							margin="normal"
							fullWidth
							onBlur={onBlur}
							onChange={(e) => {setFieldValue(`${namePrefix}.code`, e?.target?.value)}}
							disabled={false}
						/>
					</div>
					<div className="col-xl-6 col-md-6">
						<h4>
							Store
						</h4>
						<Field
							id={`${namePrefix}.storeId`}
							name={`${namePrefix}.storeId`}
							label={
								loading.storesList ? "Loading..." : "Store"
							}
							onBlur={onBlur}
							onChange={onChange}
							value={value?.storeId}
							placeholder="Store"
							component={CustomizedSelectForFormik}
							disabled={loading.storesList || false}
						>
							{storeData?.map(({ id, name }) => (
								<MenuItem key={id} value={id}>
									{name}
								</MenuItem>
							))}
						</Field>
					</div>
				</div>
			</BlockHeading>
		</>
	);
};

export default ComponentSetImageRefField;
