import { useEffect, useRef } from "react";
import {
  getTranslationLabel,
} from "_utils/commonFunctions";
import { useSelector, useDispatch } from "react-redux";
import { setFieldSelectedLanguage } from "_store/common.slice";

export const PropertyCalculatedFieldValueLabel = ({ name, values }) => {
  const dispatch = useDispatch();
  const { defaultLanguage, fieldSelectedLanguages } = useSelector((x) => x.commonData);
  const translations = useSelector((x) => x.translations);
  const refs = useRef(true);

  useEffect(() => {
    const firstRender = refs.current;
    if (firstRender && defaultLanguage) {
      refs.current = false;
    }
  });

  useEffect(() => {
    if ((!fieldSelectedLanguages.forms || fieldSelectedLanguages.forms?.length === 0) && defaultLanguage?.translate_id) {
      let selectedLanguages = localStorage.getItem('fieldSelectedLanguages');
      selectedLanguages = selectedLanguages ? JSON.parse(selectedLanguages) : [];
      dispatch(setFieldSelectedLanguage({ translations, field: 'forms', selectedLanguages: selectedLanguages?.length > 0 ? selectedLanguages : [defaultLanguage?.translate_id] }));
    }
  }, [fieldSelectedLanguages?.forms, defaultLanguage?.translate_id, dispatch, translations])

  return (
    <div className="row">
      <div className="col-xl-3">
        {name} : &nbsp;
      </div>
      <div className="col-xl-9">
        {values?.map((value) => {
          return fieldSelectedLanguages?.forms?.includes(value.translateId) &&
            <>
            <strong className="trans-label mr-2">{getTranslationLabel(value.translateId)}</strong>:&nbsp;{value?.propertyValue?.trim() !== '' ? value?.propertyValue : '-'}&nbsp;
            </>
        })}
      </div>
    </div>
  );
};
