import { Fragment } from "react";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

//Components
import { Button, Chip } from "@mui/material";

import { merchandisingActions } from "_store/merchandising.slice";
import { Form, Formik } from "formik";
import { useDispatch } from "react-redux";

const TypeTagFilter = ({
  tagOptions,
  typeOptions,
  type,
  setType,
  viewAllTags,
  setViewAllTags,
  selectedTag,
  clearSelectionTags,
  setSelectedTag,
  onFormSubmit,
  label,
  typeLabel = true,
  isNavHierarchy,
  disabled,
  children
}) => {
  const selectAllTags = (tagId, values) => {
    const clickedTag = tagOptions.filter((item) => item.id === tagId);
    if (clickedTag.length > 0 && clickedTag[0].tagValues.length > 0) {
      if (selectedTag[tagId]?.length !== clickedTag[0]?.tagValues?.length) {
        setSelectedTag({
          ...selectedTag,
          [tagId]: clickedTag[0]?.tagValues?.map((item) => item.id),
        });
      } else {
        clearSelectionTags(tagId, values);
      }
    }
  };

  const handleDelete = (type, tag) => {
    const tags = {
      ...selectedTag,
      [type]: selectedTag[type]?.filter((item) => item !== tag),
    };
    setSelectedTag({ ...tags });
    onFormSubmit(tags);
  };

  const tagItemClick = (type, tagId) => {
    if (selectedTag[type]) {
      if (selectedTag[type].includes(tagId)) {
        const tags = {
          ...selectedTag,
          [type]: selectedTag[type]?.filter((item) => item !== tagId),
        };
        setSelectedTag({ ...tags });
      } else {
        const tags = {
          ...selectedTag,
          [type]: [...selectedTag[type], tagId],
        };
        setSelectedTag({ ...tags });
      }
    } else {
      const tags = {
        ...selectedTag,
        [type]: [tagId],
      };
      setSelectedTag({ ...tags });
    }
  }

  const hasTag = tagOptions && tagOptions?.length > 0;
  const dispatch = useDispatch()
  return (
    <Fragment>
      {type && (
        <div className="row">
          <div className={isNavHierarchy ? "col-sm-6" : "col-sm-3"}>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel id={`${label}-type-select-label`}>
                {`Select ${label} ${typeLabel? 'Type': ''}`}
              </InputLabel>
              <Select
                labelId={`${label}-type-select-label`}
                id={`${label}-type-select`}
                value={type}
                onChange={(e) => setType(e.target.value)}
                label={`Select ${label} ${typeLabel? 'Type': ''}`}
                disabled={disabled}
              >
                {Array.isArray(typeOptions) &&
                  typeOptions?.map(({ id, label, site_url }, index) => (
                    <MenuItem
                      key={`${label}-type-select-option-${id}`}
                      value={id}
                      id={site_url}
                      onClick={(e) =>
                        void dispatch(merchandisingActions.setSelectedDomain(e.target.id))
                      }
                    >
                      {label}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
          {
            children
          }
          {hasTag && (
            <div className="col-sm-3">
              <Button
                type="button"
                onClick={() => setViewAllTags((status) => !status)}
                variant="contained"
                color="primary"
              >
                View All Tags
              </Button>
            </div>
          )}
        </div>
      )}

      {hasTag && viewAllTags && (
        <div className="mt-3 animation-fadeIn">
          <Formik
            initialValues={{ ...selectedTag }}
            onSubmit={onFormSubmit}
            enableReinitialize
          >
            {({ values }) => (
              <Form>
                {tagOptions?.map((item, index) => {
                  return (
                    <div className="type-tag-box" key={`tagName-${item.id}`}>
                      <div className="tags-name-clm">
                        <h5>{item.label}</h5>
                        <div className="checkbox-options">
                          <p className="mb-0 style-radio-checkbox">
                            <label>
                              <input
                                type="checkbox"
                                className="mr-2"
                                onChange={() => selectAllTags(item.id, values)}
                                checked={
                                  item?.tagValues?.length ===
                                  values[item.id]?.length
                                }
                              />
                              Select All
                            </label>
                          </p>
                          <p
                            className="mb-0"
                            onClick={() => clearSelectionTags(item.id, values)}
                            role="button"
                          >
                            <span className="material-icons">clear</span> Clear
                            Selection
                          </p>
                        </div>
                      </div>
                      <div className="tags-value-clm">
                        <div className="gap-2 mb-2 form-row w-100">
                          {item?.tagValues?.map((tagItem, subindex) => {
                            return <Chip key={`tag-chip-${item.id}-${tagItem?.id}-${String(subindex + 1)}`} className={values?.[item?.id]?.includes(tagItem?.id) ? "tag-chip-active" : "tag-chip"} role="button"
                              onClick={() => tagItemClick(item.id, tagItem.id)}
                              label={tagItem?.tagValue} variant="outlined" />
                          })}
                        </div>
                      </div>
                    </div>
                  );
                })}
                <Button
                  className="float-right"
                  type="submit"
                  variant="contained"
                  sx={{ mt: 1, mb: 2 }}
                  size="small"
                >
                  Get Data
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      )}

      {!viewAllTags &&
        Object.values(selectedTag).flat().length > 0 &&
        Object.keys(selectedTag).map((type) => {
          return selectedTag[type].map((tag, index) => {
            const label = tagOptions
              .filter((item) => item.id === type)[0]
              ?.tagValues.filter((item) => item.id === tag)[0];
            return (
              <Chip
                className="m-1"
                key={`${label?.tagValue}-${index + 1}`}
                label={label?.tagValue}
                variant="outlined"
                onDelete={() => handleDelete(type, tag)}
              />
            );
          });
        })}
    </Fragment>
  );
};

export default TypeTagFilter;
