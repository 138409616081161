/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Field } from "formik";
import BlockHeading from "_components/common/BlockHeading";
import CustomizedSelectForFormik from "_components/CustomizedSelectForFormik";
import { getFeatureGroupsForCollection } from "_store/merchandising.slice";
import { useDispatch } from "react-redux";
import { MenuItem } from "@mui/material";
import RenderFilterModal from "_components/RenderFilterModal";

const FeatureGroupItem = ({
  index,
  value,
  formOption,
  onBlur,
  onChange,
  dragHandleProps,
  mediaData,
  isLast,
  disabled = false,
  collectionList,
  setFieldValue,
  removeElement,
  collectionFeature,
  handleFeatureSequence
}) => {
  const [featureGroupdata, setFeatureGroupData] = useState([]);
  const [loading, setLoading] = useState(true)
  const namePrefix = `${formOption.name}[${index}]`;
  const dispatch = useDispatch();
  const collection = collectionList?.find(
    (data) => data?.value === value?.collectionId
  );

  const handleRemove = () => {
    removeElement(index);
    // formOption.remove(index)
  };

  const handleCollectionSelect = (selectedValue) => {
    if (selectedValue) {
      let param = `collectionId=${selectedValue}`;
      dispatch(getFeatureGroupsForCollection(param)).then((response) => {
        if (response?.payload) {
          const featuresOptionDataResponse =
            response?.payload?.collectionFeatureGroupMaps?.map((i) => ({
              value: i?.featureGroup.id,
              label: i?.featureGroup?.name || i?.featureGroup.id,
            }));
          setFeatureGroupData(featuresOptionDataResponse);
          setLoading(false)
        }
      });
    }
  };

  const handleMoveElement = (type) => {
    handleFeatureSequence(type, index)
  };
  useEffect(() => {
    handleCollectionSelect(value?.collectionId)
  }, [value?.collectionId])
  
  return (
    <>
      <BlockHeading
        index={index}
        isLast={isLast}
        prevSequence={value.sequence}
        dragHandleProps={dragHandleProps}
        onMoveElement={handleMoveElement}
        disabled={disabled}
        onRemove={collectionFeature?.length > 1 ? handleRemove : null}
      >
        <div className="row row-sm">
          <div className="col-sm-12 col-md-4 mb-3">
            <h4>Collection Name</h4>
            <RenderFilterModal
              labelid={`${namePrefix}.collectionId`}
              value={value.collectionId || ""}
              label="Collection"
              id={`${namePrefix}.collectionId`}
              name={`${namePrefix}.collectionId`}
              disabled={disabled}
              setFieldValue={setFieldValue}
              defaultItems={[
                ...(collection
                  ? [
                      {
                        label: collection?.label,
                        value: collection?.value,
                      },
                    ]
                  : []),
              ]}
              onChange={(data) => {
                handleCollectionSelect(data?.items[0]?.value);
              }}
              filterType="collection"
              fieldType="singleSelect"
            />
          </div>
          <div className="col-sm-12 col-md-4 mb-3">
            <h4>Feature Group</h4>
            <Field
              labelid={`${namePrefix}.featureGroupId`}
              id={`${namePrefix}.featureGroupId`}
              name={`${namePrefix}.featureGroupId`}
              label={`${loading ? "Loading...": "Select Features Group"}`}
              onBlur={onBlur}
              onChange={onChange}
              value={value.featureGroupId || ""}
              placeholder="Select Features Group"
              component={CustomizedSelectForFormik}
              readOnly={disabled}
            >
              {Array.isArray(featureGroupdata) &&
                featureGroupdata.length > 0 &&
                featureGroupdata?.map((item) => {
                  return (
                    <MenuItem key={`${item?.value}-feat`} value={item?.value}>
                      {item?.label}
                    </MenuItem>
                  );
                })}
            </Field>
          </div>
          <div className="col-sm-12 col-md-4 mb-3">
            <h4>Media Group</h4>
            <Field
              labelid={`${namePrefix}.mediaTypeGroupId`}
              id={`${namePrefix}.mediaTypeGroupId`}
              name={`${namePrefix}.mediaTypeGroupId`}
              label="Select Media Group"
              onBlur={onBlur}
              onChange={onChange}
              value={value?.mediaTypeGroupId ?? ""}
              placeholder="Select Media Group"
              component={CustomizedSelectForFormik}
              readOnly={disabled}
              InputLabelProps={{
                shrink: true, 
              }}
            >
              {Array.isArray(mediaData) &&
                mediaData.length > 0 &&
                mediaData?.map((item) => {
                  return (
                    <MenuItem key={`${item?.value}-feat`} value={item?.value}>
                      {item?.label}
                    </MenuItem>
                  );
                })}
            </Field>
          </div>
        </div>
      </BlockHeading>
    </>
  );
};

export default FeatureGroupItem;
