/* eslint-disable react-hooks/exhaustive-deps */
import { FormControlLabel, Switch, TextField } from "@mui/material";
import { getFeatureByFeatureGroup } from "_store/merchandising.slice";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { InputFormFields } from "_components/forms/InputFormFields";
import { Tab, Tabs } from "react-bootstrap";

const CollectionFeatureLink = ({
  disabled = false,
  readOnly = false,
  values,
  featureGroupdata,
  setFieldValue,
  translations,
  collectionList,
  handleChange,
  handleBlur,
  action
}) => {
  const [featureData, setFeatureData] = useState({});
  const [isedited, setIsedited] = useState(false)
  const dispatch = useDispatch();

  const collectionFeatureMaps = values?.collectionFeatureMaps || [];
  const prefixName = `collectionFeatureMaps`;

  const setGroupValue = useCallback((val) => {
    if (typeof setFieldValue === "function") {
      setFieldValue("collectionFeatureMaps", val);
    }
  }, [setFieldValue]);

  const fetchFeature = async (id) => {
    const isNewFeatureGroup = !values?.collectionFeatureMaps?.some(
      (data) => data?.featureGroupId === id
    );
    if (isNewFeatureGroup || collectionFeatureMaps?.length === 0 || collectionFeatureMaps?.length !== values?.featureGroups?.length) {
      try {
        const response = await dispatch(
          getFeatureByFeatureGroup({ featureGroupId: id })
        );
        const featureDataOption = response?.payload?.map((data) => ({
          id: data?.id,
          name: data?.name ?? data?.id,
          featureTranslate: data?.featureTranslate ?? [],
        }));

        setFeatureData((prev) => ({
          ...prev,
          [id]: featureDataOption,
        }));
      } catch (error) {
        console.error('Error fetching feature data:', error);
      }
    }
  };

  useEffect(() => {
    const fetchAllFeatures = async () => {
      const featureGroupIds = values?.featureGroups || [];
      await Promise.all(featureGroupIds.map((id) => fetchFeature(id)));
    };
    fetchAllFeatures();
  }, [values?.featureGroups, values?.allowedCollectionMaps]);

  const featureGroupName = useCallback(
    (id) => featureGroupdata?.find((data) => id === data?.value)?.label,
    [featureGroupdata]
  );

  const collectionName = useCallback(
    (id) => collectionList?.find((data) => id === data?.value)?.label,
    [collectionList]
  );
  // const data = [ /* Your original data */ ];

  useEffect(() => {
    if ((action === "edit" || action === "view") && !isedited && collectionFeatureMaps?.length > 0) {
      setGroupValue(collectionFeatureMaps);
      setIsedited(!isedited)

    }
    else {
      if (Object.keys(featureData).length) {
        const data = values?.featureGroups?.map((groupId) => ({
          featureGroupId: groupId,
          allowedCollectionFeatureGroupMaps: values?.allowedCollectionMaps?.map(
            (collection) => ({
              featureGroupId: groupId,
              allowedCollectionId: collection,
              allowedCollectionFeatureGroupMapTranslates: translations?.map((item) => ({
                nickNameForCollection: "",
                translateId: item.translate_id,
              })),
            })
          ),
          feature: featureData[groupId]?.map((feature) => ({
            featureId: feature?.id,
            name: feature?.name,
            featureTranslate: feature?.featureTranslate,
            isSharable: true,
            collectionFeatureNestedCollectionMaps: values?.allowedCollectionMaps?.map(
              (collection) => ({
                isActive: true,
                collectionId: collection,
                collectionFeatureMapTranslates: translations?.map((item) => ({
                  name: "",
                  translateId: item.translate_id,
                })),
              })
            ),
          })),
        }));
        setGroupValue(data);
      }
    }
  }, [featureData, values?.allowedCollectionMaps, translations, setGroupValue, action]);

  return (
    <div className="mt-3 form-row">
      <div className="col-md-12 border border-lightgray bg-light p-3 mb-3">
        <Tabs
          defaultActiveKey={
            collectionFeatureMaps?.[0]?.featureGroupId
          }
          transition={false}
          id="noanim-tab-example"
        >
          {collectionFeatureMaps?.map(
            (
              data,
              index
            ) => {
              return (
                <Tab
                  eventKey={
                    data?.featureGroupId
                  }
                  title={featureGroupName(data?.featureGroupId)}
                  className="card border pb-4"
                >
                <div className="col-xl-12 col-md-12 mt-2 border border-lightgray bg-white">
                  {
                    data?.allowedCollectionFeatureGroupMaps?.map((collection, i) => {
                      return(
                        <div className="col-xl-12 col-md-12 mt-2 border border-lightgray bg-light">
                        <div className="mt-3 form-row">
                          <div className="col-xl-4 col-md-4 mt-2">
                            <h4>
                              Collection Name
                            </h4>
                            <TextField
                              value={collectionName(collection?.allowedCollectionId)}
                              margin="normal"
                              fullWidth
                              disabled={true}
                            />
                          </div>
                          <InputFormFields
                            values={collection}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            namePrefix={`${prefixName}[${index}].allowedCollectionFeatureGroupMaps.[${i}]`}
                            name={`allowedCollectionFeatureGroupMapTranslates`}
                            valueKey="nickNameForCollection"
                            label="Nick Name"
                            labelType="h4"
                            disabled={disabled}
                          />
                        </div>
                        </div>
                      )
                    })
                  }
                </div>
                  {
                    data?.feature?.map((feature, i) => {
                      return (
                        <div className="col-xl-12 col-md-12 mt-2 border border-lightgray bg-white">
                          <div className="mt-3 form-row">
                            <div className="col-xl-4 col-md-4 mt-2">
                              <h4>
                                Features
                              </h4>
                              <TextField
                                value={feature?.name}
                                margin="normal"
                                fullWidth
                                disabled={true}
                              />
                            </div>
                            <div className="col-xl-4 col-md-3 mt-2">
                              <h4>
                                Sharable
                              </h4>
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={feature?.isSharable}
                                    onChange={(e) => {
                                      setFieldValue(
                                        `${prefixName}[${index}].feature[${i}].isSharable`,
                                        e.target.checked ? true : false
                                      );
                                    }}
                                    disabled={disabled}
                                  />
                                }
                              />
                            </div>
                          </div>
                          {!feature?.isSharable &&
                            feature?.collectionFeatureNestedCollectionMaps?.map((collectionData, colIndex) => {
                              return (
                                <div className="m-1 mt-2 form-row p-2 form-row border border-lightgray bg-light">
                                  <div className="col-xl-4 col-md-4 mt-2">
                                    <h4>
                                      Collection Name
                                    </h4>
                                    <TextField
                                      value={collectionName(collectionData?.collectionId)}
                                      margin="normal"
                                      fullWidth
                                      disabled={true}
                                    />
                                  </div>
                                  <div className="col-xl-4 col-md-3 mt-2">
                                    <h4>
                                      Active
                                    </h4>
                                    <FormControlLabel
                                      control={
                                        <Switch
                                          checked={collectionData?.isActive}
                                          onChange={(e) => {
                                            setFieldValue(
                                              `${prefixName}[${index}].feature[${i}].collectionFeatureNestedCollectionMaps.[${colIndex}].isActive`,
                                              e.target.checked ? true : false
                                            );
                                          }}
                                          disabled={disabled}
                                        />
                                      }
                                    />
                                  </div>
                                  {/* <InputFormFields
                                    values={collectionData}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    namePrefix={`${prefixName}[${index}].feature[${i}].collectionFeatureNestedCollectionMaps.[${colIndex}]`}
                                    name={`collectionFeatureMapTranslates`}
                                    valueKey="name"
                                    label="Display Name"
                                    labelType="h4"
                                    disabled={readOnly}
                                  /> */}
                                </div>
                              )
                            })
                          }
                        </div>
                      )
                    })
                  }
                </Tab>
              )
            }
          )}
        </Tabs>
      </div>
    </div>
  );
};
export default CollectionFeatureLink